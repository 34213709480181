import React from 'react'
import ReactDOM from 'react-dom'

import { ApolloProvider } from '@apollo/client'
import client from './Apollo'

import AuthenticationProvider from 'hooks/authentication'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from './Routes'

// import reportWebVitals from './reportWebVitals';
import './styles/default.scss'

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={ client }>
			<Router>
				<AuthenticationProvider>
					<Routes />
				</AuthenticationProvider>
			</Router>
		</ApolloProvider>
	</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
