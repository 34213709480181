import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, concat, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

const httpLink = new HttpLink({
    uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://api.teenranch.com/graphql',
    credentials: 'same-origin',
})

const wsLink = new WebSocketLink({
    uri: process.env.NODE_ENV === 'development' ? 'ws://localhost:4000/graphql' : 'wss://api.teenranch.com/graphql',
    options: {
        reconnect: true,
        connectionParams: {
            token: localStorage.getItem('accessToken') || null
        }
    }
})

const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  )

const authMiddleware = new ApolloLink((operation, forward) => {

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: localStorage.getItem('accessToken') || null
        }
    }))
    
    return forward(operation)
})

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, splitLink)
})

export default client