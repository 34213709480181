import React, { Suspense, lazy } from 'react'
import { useAuthentication } from 'hooks/authentication'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loader from 'components/Loader'

const Unauthorized = lazy(() => import('./pages/Unauthorized'))
const Home = lazy(() => import('./pages/Home'))
const DeviceLink = lazy(() => import('./pages/DeviceLink'))

export default function Routes() {

    const { isAuthenticated } = useAuthentication()

    if (!isAuthenticated) {

        return (
            <Suspense fallback={<Loader/>}>
                <Switch>
                    <Route exact path='/' component={ Unauthorized } />
                    <Route path='/link' component={ DeviceLink } />
                </Switch>
            </Suspense>
        )

    }

    return (
        <Suspense fallback={<Loader/>}>
            <Switch>
                <Route exact path='/' component={ Home } />
                <Route path='/:other' render={() => <Redirect to='/' />} />
            </Switch>
        </Suspense>
    )

}