import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import jwtDecode from 'jwt-decode'
import { DateTime } from 'luxon'
import { useMutation, useApolloClient } from '@apollo/client'
import { TOKEN_REFRESH } from '../operations/Auth'
import { useHistory } from 'react-router-dom'

const AuthenticationContext = createContext()

function AuthenticationProvider({ init = false, children }) {

    const client = useApolloClient()
    const history = useHistory()

    const [isAuthenticated, setAuthenticated] = useState(init)
    const [user, setUser] = useState({})
    const [tokenExpires, setTokenExpiry] = useState()
    

    const logout = useCallback(
        () => {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            setTokenExpiry()
            setAuthenticated(false)
            setUser({})
            // resetGlobalState()
            client.clearStore()
            history.push('/')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [client]
    )

    const [ refresh ] = useMutation(TOKEN_REFRESH)

    useEffect(() => {

        const accessToken = localStorage.getItem('accessToken') || false
        if (accessToken) {
            const decoded = jwtDecode(accessToken)
            const { knd, exp } = decoded
            if (knd === 'ACCESS' && exp > DateTime.now().toSeconds()) {
                setTokenExpiry(exp)
                setUser(decoded)
                setAuthenticated(true)
            }
            else {
                logout()
            }
        }

    }, [isAuthenticated, logout])

    useEffect(() => {
        const refreshTimer = setInterval(() => {
            if (tokenExpires < DateTime.now().plus({ minutes: 5 }).toSeconds()) {

                const token = localStorage.getItem('refreshToken') || null

                if (token) {

                    refresh({
                        variables: {
                            payload: {
                                token
                            }
                        }
                    })
                    .then(({data}) => {
                        const { accessToken } = data.tokenRefresh
                        const { exp } = jwtDecode(accessToken)
                        localStorage.setItem('accessToken', accessToken)
                        setTokenExpiry(exp)
                    })
                    .catch(err => {
                        if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message === 'DEVICE_NOT_FOUND') {
                            localStorage.removeItem('uuid')
                        }
                        logout()
                    })

                }

                else {
                    logout()
                }
                
            }
        }, 60000)

        return () => {
            clearInterval(refreshTimer)
        }

    }, [tokenExpires, refresh, logout])

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                user,
                setAuthenticated: (arg) => setAuthenticated(arg),
                logout: () => logout()
            }}
        >
            { children }
        </AuthenticationContext.Provider>
    )        

}

function useAuthentication() {
    return useContext(AuthenticationContext)
}

export {
    useAuthentication
}

export default AuthenticationProvider