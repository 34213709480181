import gql from 'graphql-tag'

export const LINK_DEVICE_REQUEST = gql`
    query LinkDeviceRequest($payload: LinkDeviceRequestPayload!) {
        linkDeviceRequest(payload: $payload) {
            uuid
            refreshToken
            accessToken
        }
    }
`

export const TOKEN_REFRESH = gql`
    mutation TokenRefresh($payload: TokenRefreshPayload!) {
        tokenRefresh: deviceTokenRefresh(payload: $payload) {
            accessToken
        }
    }
`